import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function revealXOpacity() {
  gsap.registerPlugin(ScrollTrigger);

  const items = document.querySelectorAll('[data-reveal-x-opacity]');

  items.forEach((item) => {

    const customDelay = parseFloat(item.getAttribute("data-reveal-delay"));

    gsap.fromTo(item,
    {
      x: -100,
      autoAlpha: 0
    },
    {
      x: 0,
      autoAlpha: 1,
      duration: 1.2,
      delay: !isNaN(customDelay) ? customDelay : 0.1,
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: item,
        markers: false,
      }
    });
  });
}

