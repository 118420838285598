import focus from '@alpinejs/focus';
import barba from '@barba/core';
import Alpine from 'alpinejs';
import gsap from 'gsap';
import Cookies from 'js-cookie';

import '/assets/styles/main.pcss';

import revealOpacity from '../scripts/animation/revealOpacity.js';
import { scrollToElementOnLoad, setExternalLink } from '../scripts/utils/url.js';
import Header from './alpine/Header.js';
import revealXOpacity from './animation/revealXOpacity.js';

// Functions
// --------------------------------------------
function headerAnimation() {
	const $elsiteLogo = document.querySelector('#siteLogo');
	const $elHeaderContact = document.querySelector('#headerContact');
	const $siteButtonMenu = document.querySelector('#siteButtonMenu');

	let tl = gsap.timeline({
		delay: 0.25,
		paused: true, // default is false
		defaults: {
			duration: 1,
			ease: 'power1.out'
		}
	});

	tl.to($elsiteLogo, { autoAlpha: 1, duration: 0.5 })
		.to($elHeaderContact, { autoAlpha: 1, duration: 0.5 }, '-=0.25')
		.to($siteButtonMenu, { autoAlpha: 1, duration: 0.5 }, '-=0.3');

	tl.play();
}

function loadImages() {
	if (window.innerWidth < 1024) return;

	const images = document.querySelectorAll('[data-image-load]');

	images.forEach((image) => {
		const imageUrl = image.getAttribute('data-src');

		image.onload = () => {
			image.classList.remove('opacity-0');
		};

		image.src = imageUrl;
	});
}

function updateTheme() {
	const pageThemes = Cookies.get('themes');
	const pageThemesJson = JSON.parse(pageThemes);
	const $elMain = document.querySelector('main[data-barba="container"]');
	const entryId = $elMain.getAttribute('data-entry-id');

	Object.entries(pageThemesJson).forEach(([key, value]) => {
		if (key === entryId) {
			$elHtml.setAttribute('data-theme', value);
		}
	});
}

function updateHeroVideo() {
	function supportsHEVCAlpha() {
		const navigator = window.navigator;
		const ua = navigator.userAgent.toLowerCase();
		const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
		const isSafari = ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
		return isSafari && hasMediaCapabilities;
	}

	const videoHero = document.getElementById('videoHero');

  if(videoHero) {
    videoHero.src = supportsHEVCAlpha() ? '/dist/videos/hero-v3.mov' : '/dist/videos/hero-v2.webm';
  }

	const videos = document.querySelectorAll('video');
	videos.forEach((video) => {
		const playPromise = video.play();

		if (playPromise !== undefined) {
			playPromise
				.then((_) => {})
				.catch((error) => {
					console.log('Unable play video:', error);
				});
		}
	});
}

// Init
// --------------------------------------------
const $elHtml = document.querySelector('html');

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.data('Header', Header);
Alpine.start();

document.addEventListener('DOMContentLoaded', () => {
	scrollToElementOnLoad();
	headerAnimation();
	revealXOpacity();
	revealOpacity();
	setExternalLink();
	updateHeroVideo();
});

window.addEventListener('load', function () {
	loadImages();
});

barba.init({
	debug: true,
	timeout: 10000,
	transitions: [
		{
			name: 'fade',
			sync: false,
			leave(data) {
				return gsap.to(data.current.container, {
					opacity: 0,
					duration: 0.2
				});
			},
			beforeEnter() {},
			enter(data) {
				gsap.from(data.next.container, {
					opacity: 0,
					duration: 0.2,
					onComplete: () => {
						headerAnimation();
						revealXOpacity();
						revealOpacity();
						setExternalLink();
					}
				});
			},
			after() {
				scrollToElementOnLoad();
				updateTheme();
				loadImages();
				updateHeroVideo();
			}
		}
	]
});
