import barba from '@barba/core';

/**
 * Sets target="_blank" for external links and adds data-external attribute.
 *
 * @param {void}
 * @return {void}
 */
export function setExternalLink() {
	const currentDomain = window.location.hostname;
	const anchors = document.querySelectorAll(`a:not([href*="${currentDomain}"], [href^="#"], .no-external-link)`);

	anchors.forEach((anchor) => {
		const href = anchor.href.toLowerCase();
		const isExternal =
			href.startsWith('http://') || href.startsWith('https://') || href.endsWith('.pdf') || href.endsWith('.docx');

		if (isExternal) {
			anchor.setAttribute('target', '_blank');
			anchor.setAttribute('data-link-external', 'true');
		}
	});
}

/**
 * Scrolls to the element on page load based on the URL hash.
 *
 * @return {void} This function does not return a value.
 */
export function scrollToElementOnLoad() {
  const offset = 200;
	let element = null;

	if (!window.location.hash) {
		window.scrollTo(0, 0);
		return;
	}

	element = document.querySelector(window.location.hash);

	if (!element) {
		window.scrollTo(0, 0);
		return;
	}

	setTimeout(() => {
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
    });
	}, 500);
}

/**
 * Function that handles blocking links.
 *
 * @return {void} This function does not return a value.
 */
export function blockLink() {
	const blocks = document.querySelectorAll("[data-block-link]");

	blocks.forEach(block => {
		const links = block.querySelectorAll("a");
		const mainLink = block.querySelector("[data-block-main-link]");

		links.forEach(link => {
			link.addEventListener("click", (e) => e.stopPropagation());

			barba.hooks.beforeLeave(() => {
				block.removeEventListener("click", handleLinkClick);
				block.removeEventListener("click", handleMainLinkClick);
			});

		});

		function handleLinkClick(e) {
			e.stopPropagation();
		}

		function handleMainLinkClick(e) {
			const noTextSelected = !window.getSelection().toString();

			if (noTextSelected) {
				mainLink.click();
			}
		}

		block.addEventListener("click", handleMainLinkClick);
	});

}
