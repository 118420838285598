import gsap from 'gsap';

export default () => ({
	lastScrollTop: 0,
	direction: null,
	showMenu: false,
	init() {
		const $elHtml = document.querySelector('html');

		$elHtml.classList.remove('overflow-hidden');
	},
	handleScroll() {
		const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
		const scrollDirection = currentScroll > this.lastScrollTop ? 'down' : 'up';

		this.lastScrollTop = currentScroll;
		this.direction = scrollDirection;
	},
	changePage(event) {
		const offset = 100;
    const pageUrl = new URL(event.currentTarget.href);
		const hash = pageUrl.hash;

		let target = null;

		event.preventDefault();

		if (hash) {
			target = document.querySelector(hash);
		}

		if (!target) return;

    window.scrollTo({
      top: target.getBoundingClientRect().top + window.pageYOffset - offset,
      behavior: 'smooth'
    });

    this.toggle();
	},
	toggle() {
		const $elHtml = document.querySelector('html');

		this.showMenu = !this.showMenu;

		if (this.showMenu) {
			this.onEnter();
		} else {
			this.onLeave();
		}

		$elHtml.classList.toggle('overflow-hidden', this.showMenu);
	},
	onEnter() {
		const $elNavLinks = this.$refs.menuListNav.querySelectorAll('a');
		const $elMenuLinks = this.$refs.menuListLinks.querySelectorAll('a');

		gsap.set($elNavLinks, { autoAlpha: 0, x: -20 });
		gsap.set($elMenuLinks, { autoAlpha: 0, y: -20 });
		gsap.set(this.$refs.menuLang, { autoAlpha: 0 });

		let tl = gsap.timeline({
			delay: 0.25,
			paused: true, // default is false
			defaults: {
				duration: 1,
				ease: 'power1.out'
			}
		});

		tl.to(this.$refs.menu, { autoAlpha: 1, duration: 0.25 })
			.to(
				$elNavLinks,
				{
					autoAlpha: 1,
					x: 0,
					stagger: 0.1,
					duration: 0.5
				},
				'-=0.1'
			)
			.to(
				$elMenuLinks,
				{
					autoAlpha: 1,
					y: 0,
					stagger: 0.1,
					duration: 0.5
				},
				'-=0.4'
			)
			.to(this.$refs.menuLang, { autoAlpha: 1, duration: 0.25 }, '-=0.4');

		tl.play();
	},
	onLeave() {
		gsap.to(this.$refs.menu, {
			autoAlpha: 0,
			duration: 0.25,
			ease: 'power1.out'
		});
	}
});
