import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function revealOpacity() {
  gsap.registerPlugin(ScrollTrigger);

  const items = document.querySelectorAll('[data-reveal-opacity]');

  items.forEach((item) => {

    const customDelay = parseFloat(item.getAttribute("data-reveal-delay"));

    gsap.fromTo(item,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 1,
        duration: 1.2,
        delay: !isNaN(customDelay) ? customDelay : 0.1,
        ease: 'power2.inOut',
        scrollTrigger: {
          trigger: item,
          markers: false,
        }
      });
  });
}

